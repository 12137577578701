import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <small>You are running this application in <b>{process.env.NODE_ENV}</b> mode.</small>
        <p>
          Welcome to Digital Team app {process.env.REACT_APP_VERSION} version.
          env update at: {process.env.REACT_APP_NOT_SECRET_CODE}
        </p>
      </header>
    </div>
  );
}

export default App;
